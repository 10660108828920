import React from 'react'
import "../pages/css/Home.css"
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img1 from '../assets/images/about-bg-1.webp';
import Blog from '../assets/images/blog1.jpg';
import Slider1 from "../assets/images/slider-1-2.webp";
import Slider2 from "../assets/images/slider-2.webp";
import Logo from "../assets/images/logo-white.webp";

import {faBookJournalWhills,faGlobeAfrica, faUniversity,faFileAlt,faGraduationCap,faVolleyball,faShareNodes,  } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';

function Home() {
  return (
    <>
    
      <Carousel fade>
      <Carousel.Item>
        <img src={Slider1}class="img-fluid" />
        <Carousel.Caption>
          <h1 style={{fontSize:"100px" }}>Kingster University</h1>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={Slider2}class="img-fluid" />
        <Carousel.Caption>
        <h1 style={{fontSize:"100px" }}>180 Majors & Minors</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={Blog} class="img-fluid"/>
        <Carousel.Caption>
       
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
   
      <div class="container-fluid  mt-3" >
        <div class="container">
         
          <div class="row ">
            <div class="col-md-6 col-lg-12">
              <div class="product-item1 text-center  h-80 p-4  ">
                <div class="">
                <div class="row ">
                <div class="col-md-6 col-lg-3 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faUniversity} className="fa-2x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">University Life</h4>
                        <h6 class="text-dark ">Overall in here</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faGraduationCap} className="fa-2x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">Graduation</h4>
                        <h6 class="text-dark ">Getting Diploma</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faVolleyball} className="fa-2x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">Athletics</h4>
                        <h6 class="text-dark ">Sport Clubs</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-3 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faShareNodes} className="fa-2x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">Social</h4>
                        <h6 class="text-dark ">Overall in here</h6>
                      </div>
                    </div>
                  </div>
                   </div>





                </div>
               </div>
            </div>
           
          </div>
        </div>
      </div>
      <div class="container-fluid py-5">
        <div class="container" >
          <div class="row g-0">
            <div class="col-lg-6 ">
              <div class=" bg-common  p-5">
                <div class=" py-5">
                  <h1 class="text-white mb-0 ">Apply for Admission </h1>
                   <p class="text-white mt-5" >We don’t just give students an education and experiences that set them up education and experiences that set  for success in a career. We help them succeed in their career—to discover a field they’re passionate about and dare to lead it</p>

                   <Nav.Link><Link to="/" class="btn btn-primary py-2 px-4 bg-light text-primary border mt-3" href="">Get Started</Link></Nav.Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow newsletter " data-wow-delay="0.3s">
              <div class=" bg-common text-center"style={{background:"white"}}>
                <div>
                  <img src={Img1} class="img-fluid" />

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className="bg-3 d-flex align-items-center">
        <div className="col-lg-12">
          <div className="container  ">
          <div class="row ">
            <div class="col-md-6 col-lg-4 " >
              <div class="product-item1 h-100 text-center">
              <img src={Logo} class="img-fluid"   />
                <h1 class="text-bg" style={{color:"#51be78", fontSize:"32px"}}>About Our University,</h1>
              
               </div>
            </div>
            <div class="col-md-6 col-lg-8" data-wow-delay="0.5s">
              <div class="product-item1   h-100 p-4 ">
              
                <h4  style={{ fontWeight:"500", color:"white", fontStyle:"italic"}}>We are one of the largest, most diverse universities in the USA with over 90,000 students in USA, and a further 30,000 studying across 180 countries for Kingster University.</h4>
                <h4 class="mt-5"style={{ fontWeight:"lighter", color:"white", fontStyle:"italic"}}>Kingster University was established by John Smith in 1920 for the public benefit and it is recognized globally. Throughout our great history, Kingster has offered access to a wide range of academic opportunities. As a world leader in higher education, the University has pioneered change in the sector.

</h4>
              </div>
            </div>
           
           
          </div>
          </div>



        </div>

      </div>
     
      <div class="container-fluid"style={{background:"#303972"}}>
        <div class="container">
        
          <div class="row g-4">
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
              <FontAwesomeIcon icon={faBookJournalWhills} className="fa-3x text-center" />
              <h5 class="mt-5 text-white">Education Services</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>
                

              
              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
              <FontAwesomeIcon icon={faGlobeAfrica} className="fa-3x text-center" />
              <h5 class="mt-5 text-white">International Hubs.</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>
                

              
              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
              <FontAwesomeIcon icon={faFileAlt} className="fa-3x text-center" />
              <h5 class="mt-5 text-white">Bachelor’s and Master’s</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>
                

             
              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
              <FontAwesomeIcon icon={faUniversity} className="fa-3x text-center" />
              <h5 class="mt-5 text-white">University Life.</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>
                

             
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Home