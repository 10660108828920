import React from 'react'
import "../pages/css/Contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faLocationDot, faEnvelope,faPhone,} from '@fortawesome/free-solid-svg-icons';
function Contact() {
  return (
    <>
      <div className="contact2 d-flex align-items-center">
        <div className="col-lg-12">
          <div className="container  ">
            <div className="row">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
              <h1 class="contact-us text-center ">CONTACT US</h1>
              <h2 class=" get-contact text-center">Contact us</h2>
               </div>

            </div>
          </div>



        </div>

      </div>
     
      <div className="container-fluid py-5 contact mt-4" >
        <div className="container">
          <div className="row g-5 align-items-center">

            <div className="col-lg-6 wow" >

              <h1 class="text-dark">Get in touch.</h1>
              <p className="">Request Free Consultation</p>
              <div className="row g-4" >
                <div className="commen-contact">
                  <div className="aboutcontact">
                  <FontAwesomeIcon icon={faLocationDot} className=' fa-2x mb-4 text-center'/>
                  </div>
                  <div className="commenleft">
                    <h4 className=" mb-0">Address</h4>
                    <p >Mention Office Address </p>
                  </div>
                </div>
                <div className="commen-contact">
                  <div className="aboutcontact">
                  <FontAwesomeIcon icon={faEnvelope} className=' fa-2x mb-4 text-center'/>
                  </div>
                  <div className="commenleft">
                    <h4 className=" mb-0">Our Mail</h4>
                    <p >hello@enfinite.com </p>
                  </div>
                </div>
                <div className="commen-contact">
                  <div className="aboutcontact">
                  <FontAwesomeIcon icon={faPhone} className=' fa-2x mb-4 text-center'/>
                  </div>
                  <div className="commenleft">
                    <h4 className=" mb-0">Our Customer Care</h4>
                    <p>+91-4859668596</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow  contact1 " >

              <div className="wow">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div >
                        <label for="name">Your Name</label>
                        <input type="text" className="form-control" id="name" />

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label for="number">Your Phone </label>
                        <input type="text" className="form-control" id="number" />

                      </div>
                    </div>
                    <div className="col-md-12">
                      <div>
                        <label for="email">Your Email </label>
                        <input type="email" className="form-control" id="email" />

                      </div>
                    </div>
                    <div className="col-12">
                      <div>
                        <label for="Organization Name">Organization Name</label>
                        <input type="text" className="form-control" id="Organization Name" />

                      </div>
                    </div>
                    <div className="col-12">
                      <div >
                        <label for="message">Message</label>
                        <textarea className="form-control" id="message"></textarea>

                      </div>
                    </div>
                    <div class="col-md-12 text-center">
                      <input class="btn" type="submit" id="button" value="Send Message" style={{ backgroundColor: "#196767", marginBottom: "10px", color: "white" }} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Contact