import React from 'react'
import '../components/Footer.css'
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import logo from '../assets/images/logo.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faDesktop, faMapMarkerAlt, faPhoneAlt, faEnvelope, } from '@fortawesome/free-solid-svg-icons'
function Footer() {
    return (
        <>
            <div class="container-fluid  footer">
                <div class="container py-5">
                    <div class="row g-5" >
                        <div class="col-md-6 col-lg-4" data-wow-delay="0.1s">

                            <Nav.Link><Link to="/" class="d-inline-block mb-3 btn-link">
                                <img src={logo} height={50} />
                            </Link></Nav.Link>

                            <p class="mb-0">Box 35300
                                1810 Campus Way NE<br/>
                                Bothell, WA 98011-8246

                                +1-2534-4456-345

                                admin@kingsteruni.edu</p>
                        </div>
                        <div class="col-md-6 col-lg-2" data-wow-delay="0.7s">
                            <h5 class="mb-4 text-common">Our Campus</h5>
                            <Nav.Link><Link to="/" class=" btn-link ">Acdemics</Link></Nav.Link>
                            <Nav.Link><Link to="/about" class=" btn-link">Planning & Administration</Link></Nav.Link>
                            <Nav.Link><Link to="/overview" class=" btn-link">Campus Safety</Link></Nav.Link>
                            <Nav.Link><Link to="/Plans" class=" btn-link">Office of the Chancellor</Link></Nav.Link>

                        </div>
                        <div class="col-md-6 col-lg-3" data-wow-delay="0.3s">
                            <h5 class="mb-4  text-common">Campus Life</h5>

                            <Nav.Link><Link to="/" class=" btn-link ">Acdemics</Link></Nav.Link>
                            <Nav.Link><Link to="/about" class=" btn-link">Planning & Administration</Link></Nav.Link>
                            <Nav.Link><Link to="/overview" class=" btn-link">Campus Safety</Link></Nav.Link>
                            <Nav.Link><Link to="/Plans" class=" btn-link">Office of the Chancellor</Link></Nav.Link>

                        </div>
                        <div class="col-md-6 col-lg-3" data-wow-delay="0.3s">
                            <h5 class="mb-4  text-common">Academics</h5>
                            <Nav.Link><Link to="/" class=" btn-link ">Acdemics</Link></Nav.Link>
                            <Nav.Link><Link to="/about" class=" btn-link">Planning & Administration</Link></Nav.Link>
                            <Nav.Link><Link to="/overview" class=" btn-link">Campus Safety</Link></Nav.Link>
                            <Nav.Link><Link to="/Plans" class=" btn-link">Office of the Chancellor</Link></Nav.Link>

                        </div>

                    </div>
                </div>
                <div class="container" data-wow-delay="0.1s">
                    <div class="copyright">
                        <div class="row py-4">
                            <div class="mx-auto text-center" data-wow-delay="0.1s" >

                                &copy;Your Site Name, All Right Reserved.
                                Designed By  <span class="text-common">Acdemics Software Solutions</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >




        </>
    )
}

export default Footer