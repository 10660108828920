import React from 'react'

import "../pages/css/Overview.css"
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookJournalWhills, faGlobeAfrica, faUniversity, faFileAlt, faCircleDot } from '@fortawesome/free-solid-svg-icons'
function Overview() {
  return (
    <>

      <div className="admission d-flex align-items-center">
        <div className="col-lg-12  gradint">

          <div className="acdemics-header1 mb-5">
            <h5 class=" text-info">Admission</h5>
            <h1 class="  text-white">Apply To Kingster</h1>


          </div>


        </div>

      </div>
      <div class="container-fluid py-5">
        <div class="container">

          <div class="row g-4">
            <div class="col-md-6 col-lg-6 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">

                <h2 class="mt-1 text-dark"> Details About How To Apply</h2>
                <p class="mt-5 text-dark">If you would like to study in the university in the heart of the city that focus on chaning the world for better to morrow, you’re choosin the right place. We do not use special formulas to select students. We look at every single applicant’s application, academic and personal, to select students who suit to our community with a full range of backgrounds. If you would like to study in the university in the heart of the city..</p>


              </div>
            </div>
            <div class="col-md-6 col-lg-6 " data-wow-delay="0.1s">
              <div class="product-item3  mt-5 h-100 p-4">
                <p class="mt-5 text-dark">If you would like to study in the university in the heart of the city that focus on chaning the world for better to morrow, you’re choosin the right place. We do not use special formulas to select students. We look at every single applicant’s application, academic and personal, to select students who suit to our community..</p>



              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid py-5" style={{ background: "#303972" }}>
        <div class="container">

          <div class="row g-4">
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
                <FontAwesomeIcon icon={faBookJournalWhills} className="fa-3x text-center" />
                <h5 class="mt-5 text-white">Education Services</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>


              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
                <FontAwesomeIcon icon={faGlobeAfrica} className="fa-3x text-center" />
                <h5 class="mt-5 text-white">International Hubs.</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>



              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
                <FontAwesomeIcon icon={faFileAlt} className="fa-3x text-center" />
                <h5 class="mt-5 text-white">Bachelor’s and Master’s</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>


              </div>
            </div>
            <div class="col-md-6 col-lg-3 " data-wow-delay="0.1s">
              <div class="product-item3   h-100 p-4">
                <FontAwesomeIcon icon={faUniversity} className="fa-3x text-center" />
                <h5 class="mt-5 text-white">University Life.</h5>
                <p class="mt-2 text-white">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized.</p>



              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="admission1 py-5 d-flex ">
        <div class="container-fluid">
          <div class="container  ">
            <div class="mx-auto text-center  " >
          
            </div>

          </div>
        </div>

      </div>

      <div class="container-fluid py-5">
        <div class="container">
          <h2>The Application Process</h2>
          <div class="row py-5 g-5">
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>1</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Start Online Submission</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>2</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Submit The Form</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>3</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Review The Submission</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>4</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Gather Necessary Documents</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>5</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Interviewing Process</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4  ">
              <div class=" d-flex">
                <div class="TechnicalSupport">
                  <span className='number'>6</span>
                </div>
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Last Decision</h4>
                  <p class="mt-2 text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
            </div>

          </div>

          <hr class="line " />
          <div class="row py-5 g-5">
            <div class="col-md-6 col-lg-6  ">
              <div class=" d-flex">
                <div class="commen-benefits product-item3">
                  <h4 class="text-common ">Things To Know First</h4>
                  <p class="mt-2 text-common1">The Common Application is required for students applying to any or all of KU’s three degree. You’ll be able to choose your campus and program s that you are interested.</p>
                </div>
              </div>
              <div class=" d-flex">
                <div class="TechnicalSupport1">
                  <FontAwesomeIcon icon={faCircleDot} className="fa-1x text-center" />
                </div>
                <div class="commen-benefits product-item3">
                  <p class=" text-common1">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence.</p>
                </div>
              </div>
              <div class=" d-flex">
                <div class="TechnicalSupport1">
                  <FontAwesomeIcon icon={faCircleDot} className="fa-1x text-center" />
                </div>
                <div class="commen-benefits product-item3">
                  <p class=" text-common1">Contact information for the counselor or other school representative who will complete your Common Application School Report and submit your official high school transcript.</p>
                </div>
              </div>
              <div class=" d-flex">
                <div class="TechnicalSupport1">
                  <FontAwesomeIcon icon={faCircleDot} className="fa-1x text-center" />
                </div>
                <div class="commen-benefits product-item3">
                  <p class=" text-common1">Contact information for one teacher (or two, maximum) who will complete the Teacher Evaluation form.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6  ">
             <div class="commen-benefits product-item3">
                  <h4 class="text-common ">When To Apply?</h4>
                 <div className='mt-5'>
                 <Table variant="light" >
                    <thead>
                      <tr >

                        <th></th>
                        <th>Application Deadline</th>
                        <th>Decision</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>

                        <td>Early Decision 1</td>
                        <td>November 1</td>
                        <td>December 15</td>
                      </tr>
                      <tr>

                        <td>Early Decision 2</td>
                        <td>January 1</td>
                        <td>February 15</td>
                      </tr>
                      <tr><td>Regular Decision</td>
                        <td>January 1</td>
                        <td>April 1</td>
                      </tr>
                    </tbody>
                  </Table>
                 </div>
                 <h4 class="text-common mt- ">When To Apply?</h4>
                 <p class=" text-common1">Documents not submitted through the online method can be mailed to:

</p>
                </div>

             

            </div>



          </div>
        </div>
      </div>
    
    </>
  )
}

export default Overview