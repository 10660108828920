import React from 'react'
import "../pages/css/Plans.css"
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import Img1 from '../assets/images/shutterstock_183400235-400x257.webp';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
function Plans() {
  return (
    <>

      <div className=" header1 d-flex align-items-center">
        <div className="col-lg-12  gradint">

          <div className="acdemics-header mb-5">
            <h5 class=" text-info">Undergraduate Program</h5>
            <h1 class="  text-white">Bachelor Of Science in Business Administration</h1>


          </div>


        </div>

      </div>

      <div class="container-fluid py-5">
        <div class="container">

          <div class="col-lg-12 ">
            <div class=" p-3 Allplans">
              <div class=" py-2">
                <div class="row g-4">
                  <div class="col-md-6 col-lg-8 wow ">
                    <div class=" d-flex">

                      <div class="commen-benefits">
                        <h2 class="text-common mb-0">Outstanding Academics essential business experience</h2>
                        <p className='mt-3'>The Academic offers include 14 majors, 15 minors, and more than 100 in major specializations so your degree will surely reflect your interests and strengths.</p>
                        <p className='mt-2'>Not only does Kingster University provide you the practical skills that is necessary to transition seamlessly into the workforce upon your graduation, but we also make sure that you will have a good sense of social justice so that you make the transition responsibly.</p>
                        <h4 className='majors'>Majors</h4><span></span>
                        <div class="row mt-5 ">
                          <div class="col-md-6 col-lg-4 wow ">
                            <div class=" majors-bg py-4">

                              <div class="majors-text">
                                <h4 class="text-white ">Accounting</h4>

                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4 wow ">
                            <div class="majors-bg py-4">

                              <div class="majors-text">
                                <h4 class="text-white ">Finance</h4>

                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4 wow ">
                            <div class="majors-bg py-4">

                              <div class="majors-text">
                                <h4 class="text-white ">Marketing</h4>

                              </div>
                            </div>
                          </div>

                        </div>
                        <p className='mt-3' style={{ fontSize: "22px", color: "#3db166" }}>If you’re an educational professional who are looking to progress into management and consultancy, or an educational planning or development role, this is the best degree for you.</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-4 py-5 " style={{ background: "#192f59" }}>
                    <div class=" d-flex">
                      <div class="commen-benefits ">
                        <h4 class="text-common mb-0 text-white">Department Contact Info</h4>
                        <h5 class="text-common mt-5">Bachelor Of Science in Business Administration</h5>
                        <h5 class=" text-common0 mt-3">1810 Campus Way NE<br /> Bothell, WA 98011-8246<br /><br />+1-2345-5432-45. <br />bsba@kuuniver.edu</h5>
                        <p className='text-common mt-3'>Mon – Fri 9:00A.M. – 5:00P.M.</p>
                        <h4 class="text-common mb-0 text-white">Social Info</h4>
                        <div className='department-info mt-3'>
                          <FontAwesomeIcon icon={faTwitter} className="fa-1x icon-cont" />
                          <FontAwesomeIcon icon={faFacebook} className="fa-1x  icon-cont " />
                          <FontAwesomeIcon icon={faInstagram} className="fa-1x  icon-cont" />
                          <FontAwesomeIcon icon={faLinkedinIn} className="fa-1x icon-cont" />
                        </div>
                        <Nav.Link><Link to="/" className="btn py-2 px-4 text-white mt-3" style={{ background: "#3db166" }} href="">Student Resources</Link></Nav.Link>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="container-fluid py-5" style={{ background: "#efefef" }}>
        <div class="container">

          <div class="row g-4">
            <div class="col-md-6 col-lg-8 " data-wow-delay="0.1s">
              <div class="  h-100 p-4">

                <h2 class="mt-2 text-dark">Why Study Here?</h2>
                <p class="mt-2" style={{ fontSize: "18px", color: "#3db166" }}>The Academic offers include 14 majors, 15 minors, and more than 100 in major specializations so your degree will surely reflect your interests and strengths..</p>
                <p class="mt-4" style={{ fontSize: "18px", color: "#6b6b6b" }}>Not only does Kingster University provide you the practical skills that is necessary to transition seamlessly into the workforce upon your graduation, but we also make sure that you will have a good sense of social justice so that you make the transition responsibly.</p>



              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid " style={{ background: "#192f59" }}> 
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-12 " >
              <div class="row">
                <div class="col-md-6 col-lg-7 " >
                  <h4 class="mt-3 text-white">The PLP in Drafting Legislation, Regulation, and Policy has been offered by the Institute of Advanced Legal Studies with considerable success since 2004</h4>
                  <button className="btn   px-4 text-white mt-5" style={{ background: "#3db166" }} href="">Apply</button>
                </div>
                <div class="col-md-6 col-lg-5 py-5" >
                  <div style={{ marginTop: "-150px" }}>
                    <img src={Img1} class="img-fluid" width={700} />

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="container-fluid py-5" style={{ background: "#efefef" }}>
        <div class="container">

          <div class="col-lg-12 ">
            <div class=" p-3 Allplans">
              <div class=" py-2">
                <div class="row g-4">
                  <div class="col-md-6 col-lg-9 wow ">
                    <Tabs
                      defaultActiveKey="profile"
                      id="fill-tab-example"
                      className="mb-3"
                      fill
                    >
                      <Tab eventKey="home" title="Benefits">
                       <h4>Why Choose Kingster?</h4>
                       <p class="mt-4">The Kingster University Alumni Association is excited to announce the arrival of KU Alumni Connect. This is a new community building platform for Kinster’s alumni. It is the only place online where you can find, and connect with, all 90,000 Kingster’s alumni. All alumni are automatically enrolled!</p>
                       <p  class="mt-4">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized globally. Throughout our great history, Kingster has offered access to a wide range of academic opportunities. As a world leader in higher education, the University has pioneered change in the sector.</p>
                      </Tab>
                      <Tab eventKey="profile" title="Self Development">
                       <h4>Self Development</h4>
                   <p class="mt-4">The Kingster University Alumni Association is excited to announce the arrival of KU Alumni Connect. This is a new community building platform for Kinster’s alumni. It is the only place online where you can find, and connect with, all 90,000 Kingster’s alumni. All alumni are automatically enrolled!</p>
                   <p  class="mt-4">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized globally. Throughout our great history, Kingster has offered access to a wide range of academic opportunities. As a world leader in higher education, the University has pioneered change in the sector</p>
                      </Tab>
                      <Tab eventKey="longer-tab" title="Spirituality">
                      <h4>Spirituality</h4>
                      <p class="mt-4">The Kingster University Alumni Association is excited to announce the arrival of KU Alumni Connect. This is a new community building platform for Kinster’s alumni. It is the only place online where you can find, and connect with, all 90,000 Kingster’s alumni. All alumni are automatically enrolled!</p>
                      <p  class="mt-4">Kingster University was established by John Smith in 1920 for the public benefit and it is recognized globally. Throughout our great history, Kingster has offered access to a wide range of academic opportunities. As a world leader in higher education, the University has pioneered change in the sector.</p>
                      </Tab>
                      <Tab eventKey="contact" title=" Alumni" >
                     <h4>Alumni</h4>
                     <p class="mt-4">The Kingster University Alumni Association is excited to announce the arrival of KU Alumni Connect. This is a new community building platform for Kinster’s alumni. It is the only place online where you can find, and connect with, all 90,000 Kingster’s alumni. All alumni are automatically enrolled!</p>
                      </Tab>
                    </Tabs>
                  </div>



                </div>

              </div>
            </div>
          </div>

        </div>
      </div>





    </>
  )
}

export default Plans


