import React from 'react'
import '../pages/css/About.css'

import Gallery1 from '../assets/images/shutterstock_734589535-400x377.webp'
import Gallery2 from '../assets/images/shutterstock_787201486-400x377.webp'
import Gallery3 from '../assets/images/shutterstock_704277943-400x377.webp'
import Gallery4 from '../assets/images/shutterstock_516640027-400x377.webp'
import Gallery5 from '../assets/images/shutterstock_331524281-400x377.webp'
import Gallery6 from '../assets/images/shutterstock_270733466-400x377.webp'
import Gallery7 from '../assets/images/shutterstock_123383902-400x377.webp'
import Gallery8 from '../assets/images/shutterstock_111027356-400x377.webp'

function About() {
    return (
        <>
          <div className="gallery d-flex align-items-center">
        <div className="col-lg-12">
          <div className="container  ">
            <div className="row">
              <div className="col-lg-12 d-flex flex-column justify-content-center">
              <h1 class="contact-us text-center ">GALLERY</h1>
              <h2 class=" get-contact text-center">Without Space</h2>
               </div>

            </div>
          </div>



        </div>

      </div>
           <div class="container-fluid py-5 ">
                <div class="container">
                    <div class="mx-auto text-center">
                        <h1 class="text-common mb-3">Grid Style</h1>
                        <p class="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquet, erat non malesuada consequat, nibh erat tempus risus.</p>
                    </div>
                    <div class="row g-4">
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery1} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery2} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery3} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery4} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery5} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery6} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery7} class="img-fluid" />
                        </div>
                        <div class="col-md-6 col-lg-3">
                        <img src={Gallery8} class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
           
          

        </>
    )
}

export default About